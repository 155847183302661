import { NavLink } from "react-bootstrap";
import { Facebook, Instagram, Twitter, WhatsApp } from "../icons";
import { SPEEDSIZE_PREFIX } from "../App";

// Codere Footer \\
function Footer() {
  const PREFIX = `${SPEEDSIZE_PREFIX}https://www.codere.bet.ar/assets/footer`;
  const payments = ["transferencia-bancaria", "debin", "marcado-pago"];
  const legals = [
    {
      name: "loteria",
      url: "https://www.saberjugar.gob.ar/",
    },
    { name: "logo-mas18" },
    { name: "JugaSeguro", url: "https://www.mendoza.gov.ar/juegosycasinos/" },
  ];
  return (
    <footer>
      <div className="sponsor mt-4">
        <img
          src={`${SPEEDSIZE_PREFIX}https://www.codere.bet.ar/_catalogs/masterpage/codere/images/splash/Sponsorship-RM.svg`}
          alt="Codere-RealMadrid"
        />

        <img
          src={`${SPEEDSIZE_PREFIX}https://www.codere.bet.ar/_catalogs/masterpage/codere/images/splash/Sponsorship-Rayados.svg`}
          alt="Codere-Rayados"
        />

        <img
          src={`${SPEEDSIZE_PREFIX}https://www.codere.bet.ar/_catalogs/masterpage/codere/images/splash/footerlogocodereriver.svg`}
          alt="Codere-RiverPlate"
        />
      </div>

      <div className="text-center mt-3">
        <div className="social-media">
          <p style={{ color: "#79c000" }}>
            ¡SEGUINOS EN NUESTRAS REDES SOCIALES!
          </p>
          <ul className="list-inline">
            <li>
              <a
                href="https://www.facebook.com/CodereArgentina/"
                title=""
                rel="nofollow"
              >
                <Facebook />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/codereargentina/"
                title=""
                rel="nofollow"
              >
                <Instagram />
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/CodereArgentina"
                title=""
                rel="nofollow"
              >
                <Twitter />
              </a>
            </li>
            <li>
              <a
                className="whatsapp"
                href="https://api.whatsapp.com/send/?phone=5491140457247&text&type=phone_number&app_absent=0"
                title=""
                rel="nofollow"
              >
                <WhatsApp />
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="container mt-5">
        <div className="footer-info-single text-center">
          <span className="title">AYUDA</span>
          <ul className="list-unstyled text-white">
            <li className="codereTrs54rsTYC">
              <a
                className="codereTrs54rsTYC"
                href="https://www.codere.bet.ar/ayuda"
                title=""
                rel="nofollow"
              >
                Términos y Condiciones
              </a>
            </li>
            <li className="codereTrs54rs">
              <a href="https://blog.codere.bet.ar/" title="" target="_blank">
                Blog Codere
              </a>
            </li>

            <li className="codereTrs54rs">
              <a href="mailto:apuestas@codere.com" title="" rel="nofollow">
                Contacto: apuestas@codere.com
              </a>
            </li>

            <li>0810-345-CODERE (2633)</li>
            <li className="codereTrs54rs">
              <a
                href="https://api.whatsapp.com/send/?phone=5491140457247&text&type=phone_number&app_absent=0"
                title=""
                rel="nofollow"
                target="_blank"
                className="whatsapp"
              >
                <WhatsApp /> +549 1140 457247 
              </a>
            </li>
          </ul>
        </div>
      </div>

      <hr className="mb-5" />
      <div className="footerlogosRTA-area">
        <div className="d-flex gap-4 justify-content-center align-items-center bg-dark py-3 mb-4">
          {legals.map((legal) => (
            <NavLink
              key={legal.name}
              as={legal.url ? "a" : "div"}
              href={legal.url ? legal.url : null}
            >
              <img
                className=""
                height={40}
                alt={legal.name}
                src={`${PREFIX}/${legal.name}-100H.webp`}
              />
            </NavLink>
          ))}
        </div>
      </div>

      <div id="legals" className="justify-content-center">
        <div className="txtFooter">
          El juego compulsivo es perjudicial para vos y tu familia
        </div>
        {/* /.txtFooter */}
        <div className="txtFooter">
          <strong>Codere.bet.ar</strong> es un sitio operado por el grupo Codere
        </div>
        {/* /.txtFooter */}
      </div>
    </footer>
  );
}

export default Footer;
