import {
  Button,
  Container,
  Nav,
  Navbar,
  Dropdown,
  Accordion,
  InputGroup,
} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { elastic as Menu } from "react-burger-menu";

import {
  Arrowrightw,
  Arrowdown,
  Deportes,
  Promociones,
  Ruletasenvivo,
  CasinoMenuIcon,
  Directo,
  Omnichannel,
} from "../icons";

import { Link, NavLink, useLocation } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { SPEEDSIZE_PREFIX } from "../App";

function Header({ regis, device }) {
  const APP_SITE = "https://m.caba.codere.bet.ar";
  const INICIO = "https://www.codere.bet.ar";
  const acceder = `${APP_SITE}/deportes/#/HomePage?openlogin=true`;

  const location = useLocation();
  const [isCasinoPage, setCasinoPage] = useState(
    location.pathname.includes("casino")
  );
  const LOGO = `${SPEEDSIZE_PREFIX}https://www.codere.bet.ar/assets/icons/logo-${
    isCasinoPage ? "casino" : "deportes"
  }.svg`;


  // Hooks
  useEffect(() => {
    setCasinoPage(location.pathname.includes("casino"));
  }, [location]);

  const [hamburger, setHamburger] = useState(false);
  const [onShow, setOnShow] = useState("");
  const menu = [
    {
      icon: Deportes,
      name: "Deportes",
      url: `${APP_SITE}/#/HomePage`,
      target: "_self",
    },
    {
      icon: Directo,
      name: "Directo",
      url: `${APP_SITE}/#/DirectosPage`,
      target: "_self",
    },
    {
      icon: CasinoMenuIcon,
      name: "Casino",
      url: `${APP_SITE}/#/casino/CasinoPage?lobby=ArgentinaCaba`,
      target: "_self",
    },
    {
      icon: Ruletasenvivo,
      name: "En Vivo",
      url: `${APP_SITE}/#/casino/CasinoEnVivoPage?lobby=EnVivoAR`,
      target: "_self",
    },

    {
      icon: Promociones,
      name: "Promociones",
      url: `${APP_SITE}/#/mso/PromotionsPage`,
      target: "_self",
    },
    {
      icon: Omnichannel,
      name: "Nuestras Salas",
      url: "https://bingos.codere.bet.ar",
      target: "_blank",
    },
  ];

  const seo_menu = [
    { name: "Casino", url: "/casino" },
    {
      name: "Casino en vivo",
      url: "/casino/casino-en-vivo",
    },
    {
      name: "Ruleta",
      url: "/casino/ruleta",
    },
    {
      name: "Slots",
      url: "/casino/slots",
    },
    {
      name: "Blackjack",
      url: "/casino/blackjack",
    },
    {
      name: "Baccarat",
      url: "/casino/baccarat",
    },
    {
      name: "Eventos Deportivos",
      url: "/eventos-deportivos",
    },
    {
      name: "Cuotas Deportivas",
      url: "/cuotas-deportivas",
    },
    {
      name: "Codere Blog",
      url: "https://blog.codere.bet.ar/",
      target: "_blank",
    },
  ];

  const hamburgerHandler = () => {
    hamburger ? setOnShow("") : setOnShow("open");
    setHamburger(!hamburger);
  };
  return (
    <Navbar className="header_nav stroke" variant="dark">
      {device ? (
        <div id="father">
          <div
            id="outer-container"
            className={`${isCasinoPage ? "casino" : "deportes"}-burger`}
          >
            <Menu
              id="elastic"
              left
              customCrossIcon={false}
              pageWrapId={"page-wrap"}
              outerContainerId={"outer-container"}
              burgerButtonClassName={onShow}
              isOpen={hamburger}
              onOpen={hamburgerHandler}
              onClose={hamburgerHandler}
            >
              {menu.map((m, k) => (
                <NavLink
                  key={k}
                  to={m.url}
                  href={m.url}
                  target={m.target}
                  rel="nofollow"
                >
                  <div
                    className={"mobMenuSVGwrapper d-flex align-items-center"}
                  >
                    <m.icon />
                    {m.name}
                  </div>

                  <Arrowrightw />
                </NavLink>
              ))}

              <Accordion className="hamb-accordion">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <span style={{ color: "#79c000" }}>¿Cómo jugar?</span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      {seo_menu.map((s, k) => (
                        <li key={k}>
                          <Link
                            to={s.url}
                            href={`${s.target ? s.url : `${INICIO}${s.url}`}`}
                            target={s.target || null}
                            onClick={hamburgerHandler}
                          >
                            {s.name}
                            <Arrowrightw />
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Menu>
            <main id="page-wrap"></main>
          </div>
          <a href={`${APP_SITE}/#/HomePage`} rel="nofollow">
            <img src={LOGO} alt="Logo Casa de Apuestas Codere - Real Madrid " />
          </a>
          <div id="header_buttons">
            <Button href={acceder} className="acceder-button" rel="nofollow">
              Acceder
            </Button>
            <Button href={regis} className="registrate-button" rel="nofollow">
              Registrate
            </Button>
          </div>
        </div>
      ) : (
        <Container>
          <InputGroup className="header-group-left">
            <Nav.Link href={`${APP_SITE}/#/HomePage"`} rel="nofollow">
              <LazyLoadImage
                src={LOGO}
                height={35}
                alt="Logo Casa de Apuestas Codere - Real Madrid "
              />
            </Nav.Link>

            <Nav className="me-auto">
              {menu.map((m, k) => (
                <Nav.Link key={k} href={m.url} target={m.target} rel="nofollow">
                  <span style={{ textTransform: "uppercase" }}>{m.name}</span>
                </Nav.Link>
              ))}
            </Nav>
          </InputGroup>
          <div className="header-group-right">
            <Dropdown>
              <Dropdown.Toggle className="header_btn">
                <Arrowdown />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {seo_menu.map((s, k) => (
                  <Dropdown.Item
                    as={Link}
                    key={k}
                    to={s.url}
                    href={`${s.target ? s.url : `${INICIO}${s.url}`}`}
                    target={s.target || null}
                  >
                    {s.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <div className="d-flex gap-2">
              <Button
                href={acceder}
                // onClick={handleShow}
                className="acceder-button"
                rel="nofollow"
              >
                Acceder
              </Button>
              <Button href={regis} className="registrate-button" rel="nofollow">
                Registrate
              </Button>
            </div>
          </div>
        </Container>
      )}
    </Navbar>
  );
}

export default Header;
